<template>
  <div class="digital-certificate">
    <app-header icon="coins" title="Certificado Digital"></app-header>

    <section>
      <div class="card p-4">
        <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>

        <div class="row mt-2">
          <div class="col-sm-12 col-lg-12 col-xl-12 d-flex">
            <h2 class="subtitle">
              Dados para geração de receitas assinadas digitalmente
            </h2>
            <b-tooltip
              label="Caso não enviado, as receitas médicas não serão assinadas digitalmente"
              position="is-right"
            >
              <b-icon icon="question-circle" type="is-primary"> </b-icon>
            </b-tooltip>
          </div>
        </div>

        <div v-if="!isNew" class="row mt-2" style="align-item: baseline">
          <div class="col-6">
            <app-input
              label="Certificado Digital"
              v-model="certificate.file"
              :disabled="true"
            ></app-input>
          </div>
          <div class="col-3 delete-button">
            <b-tooltip label="Deletar">
              <b-button
                @click="onClickDelete(certificate.id)"
                type="is-danger"
                size="is-small"
                icon-right="trash"
              />
            </b-tooltip>
          </div>
        </div>

        <div v-if="isNew" class="certificate-form">
          <div class="row mt-2">
            <div class="col-6">
              <app-file-picker
                label="Arquivo PFX"
                accept=".pfx"
                v-model="certificate.file"
                :errors="errors.file"
                @fileDeleted="() => (certificate.file = null)"
              >
              </app-file-picker>
            </div>

            <div class="col-6">
              <app-input
                label="Senha do arquivo"
                type="password"
                v-model="certificate.password"
                :errors="errors.password"
              ></app-input>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12 button-container">
              <b-button
                @click="onSubmit"
                :loading="isLoading"
                :disabled="isLoading"
                type="is-primary"
              >
                Salvar
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DoctorService from '@/services/doctor.service';

export default {
  data: () => ({
    isLoading: false,
    isNew: true,
    certificate: {
      id: null,
      file: null,
      password: null,
    },
    errors: {},
  }),

  methods: {
    onSubmit() {
      this.isLoading = true;
      this.errors = {};

      DoctorService.storeCertificate({ ...this.certificate })
        .then(({ data }) => {
          this.certificate.file = data.certificate_path.split('/').pop();
          this.certificate.id = data.id;
          this.isNew = false;

          this.$buefy.snackbar.open('Certificado salvo com sucesso!');
        })
        .catch(({ response }) => {
          const { data } = response;

          this.$buefy.snackbar.open({
            message: 'Erro inesperado ao salvar certificado',
            type: 'is-danger',
          });

          this.errors = data.message;
        })
        .finally(() => (this.isLoading = false));
    },
    onClickDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Excluindo Certificado Digital',
        message:
          'Tem certeza que deseja <b>excluir</b> este certificado? Essa ação não pode ser desfeita.',
        cancelText: 'Cancelar',
        confirmText: 'Excluir Certificado',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.delete(id),
      });
    },
    delete(id) {
      this.isLoading = true;

      DoctorService.deleteCertificate(id)
        .then(() => {
          this.certificate = {};
          this.isNew = true;
          this.$buefy.snackbar.open('Certificado deletado com sucesso!');
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            message: 'Erro inesperado ao deletar certificado',
            type: 'is-danger',
          });
        })
        .finally(() => (this.isLoading = false));
    },
    loadDoctorCertificate() {
      this.isLoading = true;

      DoctorService.getCertificate()
        .then(({ data }) => {
          if (!Object.keys(data).length) return;

          this.certificate.file = data.certificate_path.split('/').pop();
          this.certificate.id = data.id;

          this.isNew = false;
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.loadDoctorCertificate();
  },
};
</script>

<style lang="scss" scoped>
.delete-button {
  margin-top: 38px;
  margin-left: -15px;
}
</style>
